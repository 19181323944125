<template>
  <div>
    <table class="table">
      <tr>
        <th>Gruppe</th>
        <th>Feld</th>
        <th>Deutsch</th>
        <th>Englisch</th>
      </tr>
      <template v-for="group in groups">
        <tr :key="group.key" style="background-color: lightgray">
          <td>{{ group.key }}</td>
          <td></td>
          <td>{{ $t(group.i18n, 'de') }}</td>
          <td>{{ $t(group.i18n, 'en') }}</td>
        </tr>

        <tr v-for="detail in group.details" :key="group.key + '#' + detail">
          <td></td>
          <td>{{ detail }}</td>
          <td>{{ getDetailName(detail, 'de') }}</td>
          <td>{{ getDetailName(detail, 'en') }}</td>
        </tr>
      </template>
    </table>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: 'InternalEntryRestrictionGrouped',
  props: [
    "groups"
  ],
  data() {
    return {
      tab: 0
    }
  },
  components: {
  },
  mounted: function() {
  },
  computed: {
  },
  methods: {
    getDetailName(detail, language) {
      return this.$i18n.t('detailtype.' + detail, language)
    }
  },
  watch: {
  }
}
</script>
