
<template>
  <div class="main-view">

  <v-tabs v-model="tab">
    <v-tab>Ampel-Felder</v-tab>
    <v-tab>Generierte Felder</v-tab>
    <v-tab>Ausgabe: DM</v-tab>
    <v-tab>Ausgabe: Meine Reise Seite</v-tab>
    <v-tab>Ausgabe: Der Reisemanager</v-tab>
  </v-tabs>

  <v-tabs-items v-model="tab" class="mt-4">
    <v-tab-item>
      <InternalEntryRestrictionList
        :details="entryRestrictionDetailTypes"
      />
    </v-tab-item>

    <v-tab-item>
      <InternalEntryRestrictionList
        :details="entryRestrictionDetailTypesGenerated"
      />
    </v-tab-item>

    <v-tab-item>
      <InternalEntryRestrictionGrouped :groups="entryRestrictionDetailDisplayGroupProfessional" />
    </v-tab-item>

    <v-tab-item>
      <InternalEntryRestrictionGrouped :groups="entryRestrictionDetailDisplayGroupEnduser" />
    </v-tab-item>

    <v-tab-item>
      <InternalEntryRestrictionGrouped :groups="entryRestrictionDetailDisplayGroupBasic" />
    </v-tab-item>
  </v-tabs-items>


  </div>
</template>

<script>
import InternalEntryRestrictionList from '@/components/internal/InternalEntryRestrictionList'
import InternalEntryRestrictionGrouped from '@/components/internal/InternalEntryRestrictionGrouped'
import { generatedEntryRestrictionDetailTypes, generatedEntryRestrictionDetailTypesGenerated,
         generatedEntryRestrictionDetailDisplayGroupProfessional, generatedEntryRestrictionDetailDisplayGroupEnduser,
         generatedEntryRestrictionDetailDisplayGroupBasic } from '@/generated/store/details';

export default {
  name: 'EntryRestrictionOverview',
  data() {
    return {
      tab: 0
    }
  },
  components: {
    InternalEntryRestrictionList,
    InternalEntryRestrictionGrouped
  },
  mounted: function() {
  },
  computed: {
    entryRestrictionDetailTypes() {
      return generatedEntryRestrictionDetailTypes
    },
    entryRestrictionDetailTypesGenerated() {
      return generatedEntryRestrictionDetailTypesGenerated
    },
    entryRestrictionDetailDisplayGroupProfessional() {
      return generatedEntryRestrictionDetailDisplayGroupProfessional
    },
    entryRestrictionDetailDisplayGroupEnduser() {
      return generatedEntryRestrictionDetailDisplayGroupEnduser
    },
    entryRestrictionDetailDisplayGroupBasic() {
      return generatedEntryRestrictionDetailDisplayGroupBasic
    }
  },
  methods: {
  },
  watch: {
  }
}
</script>

<style scoped>

  .main-view {
    padding: 2rem;
  }

</style>