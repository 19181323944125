<template>
  <div>
    <table class="table">
      <tr>
        <th>Admin-Kategorie</th>
        <th>Feld</th>
        <th>Typ</th>
        <th>Ja-Positiv</th>
        <th>Deutsch</th>
        <th>Englisch</th>
      </tr>
      <template v-for="category in categories">
        <tr :key="category.code" style="background-color: lightgray">
          <td>{{ category.code }}</td>
          <td></td>
          <td></td>
          <td></td>
          <td>{{ category.nameDe }}</td>
          <td>{{ category.nameEn }}</td>
        </tr>

        <tr v-for="(detail, code) in category.details" :key="category.code + '#' + code">
          <td></td>
          <td>{{ code }}</td>
          <td>{{ detail.type }}</td>
          <td>
            <span v-if="detail.positiveYes">Ja</span>
          </td>
          <td>{{ $t(detail.i18n, 'de') }}</td>
          <td>{{ $t(detail.i18n, 'en') }}</td>
        </tr>
      </template>
    </table>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { generatedEntryRestrictionDetailTypesCategories } from '@/generated/store/details';


export default {
  name: 'InternalEntryRestrictionList',
  props: [
    "details"
  ],
  data() {
    return {
      tab: 0
    }
  },
  components: {
  },
  mounted: function() {
  },
  computed: {
    categories() {
      let categories = {}

      for (const [detail, detailData] of Object.entries(this.details)) {
        if (!categories[detailData.category]) {
          categories[detailData.category] = {
            code: detailData.category,
            nameDe: this.getCategoryName(detailData.category, 'de'),
            nameEn: this.getCategoryName(detailData.category, 'en'),
            details: {}
          }
        }

        categories[detailData.category]['details'][detail] = detailData
      }

      return categories
    }
  },
  methods: {
    getCategoryName(category, language) {
      if (category == '') {
        return '-'
      }

      const i18nKey = generatedEntryRestrictionDetailTypesCategories[category].i18n
      return this.$i18n.t(i18nKey)
    },
  },
  watch: {
  }
}
</script>
